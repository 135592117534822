<template>
  <div id="portfolio">
    <SiteNav></SiteNav>
    <section v-if="portfolio === 'ERROR'">
      <h3>
        We're sorry, this category doesn't exist yet. 
        Please click the top left icon to return to the overall view 
        and let us know how we can improve. Thanks!
      </h3>
    </section>
    <section v-else>
      <div class="col1">
        <div class="container">
          <h3 class="bottom-padding">{{ category }}</h3>
          <p class="bottom-padding">{{ portfolio }}</p>
          <div class="current-wellth">
            <img src="@/assets/logo.svg" alt="logo" class="top-padding"/>
            <h3 v-if="resultsFiltered.length">
              <vue-odometer
                :value="chartResult"
                format="(,ddd).d"
                animation="smooth"
                :duration="500"
              />
            </h3>
            <h3 v-else>--</h3>
          </div>
          <h6 v-if="resultsFiltered.length" v-bind:class="lineColor(difference)" class="bottom-padding"><span class="arrow" v-html="arrow(difference)"></span>{{ difference }} ({{ percentDifference }}) <span class="rangeName" v-html="rangeNameSelection"></span></h6>
          <h6 v-else class="bottom-padding top-padding">Add a result to start tracking your wellth!</h6>
          <div class="big-chart">
            <v-chart
              :options="line"
              @updateAxisPointer="updateAxisPointer"
              autoresize
            />
          </div>
          <div class="range-picker">
            <button @click="changeRange('1D')"><h6 v-bind:class="{ 'button-selected': range == '1D' }">1D</h6></button>
            <button @click="changeRange('1W')"><h6 v-bind:class="{ 'button-selected': range == '1W' }">1W</h6></button>
            <button @click="changeRange('1M')"><h6 v-bind:class="{ 'button-selected': range == '1M' }">1M</h6></button>
            <button @click="changeRange('3M')"><h6 v-bind:class="{ 'button-selected': range == '3M' }">3M</h6></button>
            <button @click="changeRange('1Y')"><h6 v-bind:class="{ 'button-selected': range == '1Y' }">1Y</h6></button>
            <button @click="changeRange('5Y')"><h6 v-bind:class="{ 'button-selected': range == '5Y' }">5Y</h6></button>
            <button @click="changeRange('ALL')"><h6 v-bind:class="{ 'button-selected': range == 'ALL' }">ALL</h6></button>
          </div>
          <hr>
        </div>
        <div class="container info-panel-narrow">
          <h3>By {{ categorization }}</h3>
          <hr>
          <div class="radar-chart">
            <v-chart
              :options="radar"
              @click="radarSelection"
              autoresize
            />
          </div>
          <!-- <button @click="openFeedbackModal()"><h5>Want more?</h5></button> -->
          <!-- <button><h5><a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a></h5></button> -->
        </div>
        <div class="container wide">
          <h3>Markers</h3>
          <hr>
          <div class="marker-headers">
            <button @click="changeMarkerSort('Name')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Name' }">Name</h5></button>
            <button @click="changeMarkerSort('Current')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Current' }">Current</h5></button>
            <button @click="changeMarkerSort('Wellth')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Wellth' }">Wellth</h5></button>
            <button @click="changeMarkerSort('Age')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Age' }">Updated</h5></button>
            <button @click="changeMarkerSort('Sparkline')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Sparkline' }">Past Year</h5></button>
          </div>
          <div v-for="marker in markerSummaries" :key="marker.id" class="marker-summary-row">
            <router-link :to="'/markers/' + marker.symbol.toLowerCase()">
              <div v-if="marker.sparklineResults.length">
                <div class="marker-summary">
                  <h5>{{ marker.symbol }}<br/><span class="marker-subtitle">{{ marker.name }}</span></h5>
                  <h5>{{ { format: marker.units, val: marker.currentResult } | formatValue }}</h5>
                  <!-- <h5 v-bind:class="lineColor(difference)"><span class="arrow" v-html="arrow(difference)"></span> ({{ percentDifference }}) <span class="rangeName" v-html="rangeNameSelection"></span></h5> -->
                  <h5>{{ marker.wellth }}</h5>
                  <h5>{{ marker.lastUpdated | formatDate }}</h5>
                  <div class="small-chart">
                    <v-chart
                      :options="sparkLine(marker)"
                      autoresize
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="marker-summary">
                  <h5>{{ marker.symbol }}<br/><span class="marker-subtitle">{{ marker.name }}</span></h5>
                  <p>--</p>
                  <p>--</p>
                  <p>Add a result!</p>
                  <p>--</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="container narrow">
          <h3>Markers</h3>
          <hr>
          <div class="marker-headers">
            <button @click="changeMarkerSort('Name')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Name' }">Name</h5></button>
            <button @click="changeMarkerSort('Current')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Current' }">Current</h5></button>
            <!-- <button @click="changeMarkerSort('Wellth')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Wellth' }">Wellth</h5></button> -->
            <!-- <button @click="changeMarkerSort('Age')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Age' }">Updated</h5></button> -->
            <button @click="changeMarkerSort('Sparkline')"><h5 v-bind:class="{ 'markerSort-selected': markerSort == 'Sparkline' }">Past Year</h5></button>
          </div>
          <div v-for="marker in markerSummaries" :key="marker.id" class="marker-summary-row">
            <router-link :to="'/markers/' + marker.symbol.toLowerCase()">
              <div v-if="marker.sparklineResults.length">
                <div class="marker-summary">
                  <h5>{{ marker.symbol }}<br/><span class="marker-subtitle">{{ marker.name }}</span></h5>
                  <h5>{{ { format: marker.units, val: marker.currentResult } | formatValue }}</h5>
                  <!-- <h5 v-bind:class="lineColor(difference)"><span class="arrow" v-html="arrow(difference)"></span> ({{ percentDifference }}) <span class="rangeName" v-html="rangeNameSelection"></span></h5> -->
                  <!-- <h5>{{ marker.wellth }}</h5> -->
                  <!-- <h5>{{ marker.lastUpdated | formatDate }}</h5> -->
                  <div class="small-chart">
                    <v-chart
                      :options="sparkLine(marker)"
                      autoresize
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="marker-summary">
                  <h5>{{ marker.symbol }}<br/><span class="marker-subtitle">{{ marker.name }}</span></h5>
                  <p>--</p>
                  <p>Add a result!</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- <hr> -->
        <footer>
          <p>
            &#169; 2021 Wellthmark. All rights reserved.
          </p>
          <p>
            Contact us at help@wellthmark.com
          </p>
          <p>
            <a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a>
          </p>
          <p>
            <a href="https://forms.gle/4sxiLjPDLEaWK8LWA" target="_blank">Subscribe</a>
          </p>
        </footer>
      </div>
      <div class="col2">
        <div class="info-panel">
          <h3>By {{ categorization }}</h3>
          <hr>
          <div class="radar-chart">
            <v-chart
              :options="radar"
              @click="radarSelection"
              autoresize
            />
          </div>
          <!-- <button @click="openFeedbackModal()"><h5>Want more?</h5></button> -->
          <button><h5><a href="https://forms.gle/UELeh1MX4RuWYZKQ9" target="_blank">Leave Feedback</a></h5></button>
        </div>
      </div>
    </section>
    <!-- <transition name="fade">
      <div v-if="showFeedbackModal" class="e-modal">
        <div class="e-container">
          <h3>We love feedback!</h3>
          <hr class="bottom-padding">
          <form @submit.prevent>
            <label for="feedback" class="bottom-padding">What can we do better?</label>
            <textarea v-model.trim="feedback.note" :placeholder=Feedback></textarea>
            <hr>
            <label for="feedback" class="bottom-padding">Would you be interested in...</label>
            <div class="checkbox bottom-padding top-padding">
              <input type="checkbox" id="checkbox" v-model="feedback.api">
              <label for="checkbox">API integration with popular wearables</label>
            </div>
            <div class="checkbox bottom-padding top-padding">
              <input type="checkbox" id="checkbox" v-model="feedback.benchmarking">
              <label for="checkbox">Global and demographic benchmarking</label>
            </div>
            <div class="checkbox bottom-padding top-padding">
              <input type="checkbox" id="checkbox" v-model="feedback.teams">
              <label for="checkbox">Abilty to share and compete with teams of friends</label>
            </div>
            <hr>
            <div class="checkbox bottom-padding top-padding">
              <input type="checkbox" id="checkbox" v-model="feedback.email">
              <label for="checkbox">Send me infrequent emails on future developments.</label>
            </div>
          </form>
          <div class="modal-buttons bottom-padding">
            <button @click="createFeedback()" :disabled="feedback.note === '' || feedback.note == null" class="save"><h5>share</h5></button>
            <button @click="closeFeedbackModal()" class="cancel"><h5>cancel</h5></button>
          </div>
          <h6 class="bottom-padding top-padding">Thank you!</h6>
          <h6 class="bottom-padding top-padding">- The Wellthmark Team</h6>
          <h6>
            <a href="https://forms.gle/cyNaDmSQdDoTEhMa8" target="_blank">Leave more feedback</a>
          </h6>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import debounce from 'lodash/debounce'
import VueOdometer from 'v-odometer/src'
import SiteNav from '@/components/SiteNav'
import * as fb from '../firebase'

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/radar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'

export default {
  components: {
    'v-chart': ECharts,
    'SiteNav': SiteNav,
    'vue-odometer': VueOdometer
  },
  data() {
    return {
      chartIndex: 0,
      // feedback: {
      //   api: false,
      //   benchmarking: false,
      //   email: false,
      //   note: '',
      //   teams: false
      // },
      // markersFiltered: [],
      markerSort: 'Name',
      markerSortAscending: true,
      // portfolio: 'Overall Wellth',
      range: 'ALL',
      // resultsFiltered: [],
      // showFeedbackModal: false
    }
  },
  // created() {
  //   // userProfile
  //   fb.usersCollection.doc(fb.auth.currentUser.uid).get().then((doc) => {
  //     this.userProfile = doc.data()
  //     this.userProfile.id = fb.auth.currentUser.uid
  //   })

  //   // category
  //   this.category = this.$route.params.category.toUpperCase()

  //   // categorization, markersFiltered, portfolio, results
  //   if (this.category === 'ALL') {
  //     this.categorization = 'Category'

  //     fb.markersCollection
  //       .onSnapshot(snapshot => {
  //         snapshot.forEach(doc => {
  //           let info = doc.data()
  //           info.id = doc.id
  //           this.markersFiltered.push({
  //             categorySymbol: info.categorySymbol,
  //             symbol: info.symbol,
  //             wellthMaxFemale: info.wellthMaxFemale,
  //             wellthMaxMale: info.wellthMaxMale,
  //             wellthMinFemale: info.wellthMinFemale,
  //             wellthMinMale: info.wellthMinMale
  //           })
  //         })
  //       })

  //     fb.resultsCollection
  //       .where('userId', '==', fb.auth.currentUser.uid)
  //       .orderBy('createdOn', 'desc')
  //       .onSnapshot(snapshot => {
  //         snapshot.forEach(doc => {
  //           let info = doc.data()
  //           info.id = doc.id
  //           this.resultsFiltered.push({
  //             createdOn: info.createdOn.toDate(),
  //             symbol: info.symbol,
  //             value: info.value
  //           })
  //         })
  //       })
  //   } else {
  //     const markerList = []
  //     this.categorization = 'Marker'
  //     this.portfolio = 'ERROR'

  //     fb.markersCollection
  //       .where('categorySymbol', '==', this.category)
  //       .onSnapshot(snapshot => {
  //         snapshot.forEach(doc => {
  //           let info = doc.data()
  //           info.id = doc.id
  //           this.markersFiltered.push(info)
  //           this.portfolio = info.category
  //           markerList.push(info.symbol)
  //         })
  //       })

  //     fb.resultsCollection
  //       .where('symbol', 'in', markerList)
  //       .where('userId', '==', fb.auth.currentUser.uid)
  //       .orderBy('createdOn', 'desc')
  //       .onSnapshot(snapshot => {
  //         snapshot.forEach(doc => {
  //           let info = doc.data()
  //           info.id = doc.id
  //           this.resultsFiltered.push({
  //             createdOn: info.createdOn.toDate(),
  //             symbol: info.symbol,
  //             value: info.value
  //           })
  //         })
  //       })
  //   }

  //   // wellth
  //   this.resultsFiltered = this.resultsFiltered.map((result) => {
  //     let marker = this.markersFiltered.filter((marker) => {
  //       return marker.symbol === result.symbol
  //     })[0]
  //     let wellth = 0
  //     if (this.userProfile.gender == 'female') {
  //       wellth = parseFloat(Math.min(Math.max((100 * (result.value - marker.wellthMinFemale) / (marker.wellthMaxFemale - marker.wellthMinFemale)),0),100).toFixed(marker.decimals))
  //     } else {
  //       wellth = parseFloat(Math.min(Math.max((100 * (result.value - marker.wellthMinMale) / (marker.wellthMaxMale - marker.wellthMinMale)),0),100).toFixed(marker.decimals))
  //     }
  //     return {
  //       ...result,
  //       wellth: wellth
  //     }
  //   })
  // },
  computed: {
    ...mapState([
      'markers',
      'results',
      'userProfile'
    ]),
    category() {
      return this.$route.params.category.toUpperCase()
    },
    categorization() {
      if (this.category === 'ALL') {
        return 'Category'
      } else {
        return 'Marker'
      }
    },
    portfolio() {
      if (this.category === 'ALL') {
        return 'Overall Wellth'
      } else {
        if (this.markersFiltered.length > 0) {
          return this.markersFiltered.filter((marker) => {
            return marker.categorySymbol === this.category
          })[0].category
        } else {
          return 'ERROR'
        }
      }
    },
    rangeInfo() {
      let allInfo = {}
      if (this.firstResultDate > moment().startOf('hour').subtract(47, 'hours').toDate()) {
        allInfo = {
          end: moment().startOf('hour').add(1, 'hours').toDate(),
          format: 'HH:mm',
          number: 15,
          unit: 'minutes'
        }
      } else if (this.firstResultDate > moment().startOf('hour').add(1, 'hours').subtract(2, 'weeks').toDate()) {
        allInfo = {
          end: moment().startOf('hour').add(1, 'hours').toDate(),
          format: 'MMM D HH:mm',
          number: 1,
          unit: 'hours'
        }
      } else if (this.firstResultDate > moment().startOf('day').add(1, 'days').subtract(2, 'months').toDate()) {
        allInfo = {
          end: moment().startOf('day').add(1, 'days').toDate(),
          format: 'MMM D HH:mm',
          number: 6,
          unit: 'hours'
        }
      } else if (this.firstResultDate > moment().startOf('day').add(1, 'days').subtract(6, 'months').toDate()) {
        allInfo = {
          end: moment().startOf('day').add(1, 'days').toDate(),
          format: 'MMM D, YYYY',
          number: 1,
          unit: 'days'
        }
      } else if (this.firstResultDate > moment().startOf('week').add(1, 'weeks').subtract(2, 'years').toDate()) {
        allInfo = {
          end: moment().startOf('week').add(1, 'weeks').toDate(),
          format: 'MMM D, YYYY',
          number: 1,
          unit: 'weeks'
        }
      } else {
        allInfo = {
          end: moment().startOf('month').add(1, 'months').toDate(),
          format: 'MMM YYYY',
          number: 1,
          unit: 'months'
        }
      }
      let addMinutes = 15 * Math.ceil(moment().minutes() / 15)
      switch(this.range) {
        case '1D':
          return {
            end: moment().startOf('hour').add(addMinutes, 'minutes').toDate(),
            format: 'HH:mm',
            name: 'Past Day',
            number: 15,
            start: moment().startOf('hour').add(addMinutes, 'minutes').subtract(24, 'hours').toDate(),
            unit: 'minutes'
          }
        case '1W':
          return {
            end: moment().startOf('hour').add(1, 'hours').toDate(),
            format: 'MMM D HH:mm',
            name: 'Past Week',
            number: 1,
            start: moment().startOf('hour').add(1, 'hours').subtract(1, 'weeks').toDate(),
            unit: 'hours'
          }
        case '1M':
          return {
            end: moment().startOf('day').add(1, 'days').toDate(),
            format: 'MMM D HH:mm',
            name: 'Past Month',
            number: 6,
            start: moment().startOf('day').add(1, 'days').subtract(1, 'months').toDate(),
            unit: 'hours'
          }
        case '3M':
          return {
            end: moment().startOf('day').add(1, 'days').toDate(),
            format: 'MMM D, YYYY',
            name: 'Past 3 Months',
            number: 1,
            start: moment().startOf('day').add(1, 'days').subtract(3, 'months').toDate(),
            unit: 'days'
          }
        case '1Y':
          return {
            end: moment().startOf('week').add(1, 'weeks').toDate(),
            format: 'MMM D, YYYY',
            name: 'Past Year',
            number: 1,
            start: moment().startOf('week').add(1, 'weeks').subtract(1, 'years').toDate(),
            unit: 'weeks'
          }
        case '5Y':
          return {
            end: moment().startOf('month').add(1, 'months').toDate(),
            format: 'MMM YYYY',
            name: 'Past 5 Years',
            number: 1,
            start: moment().startOf('month').add(1, 'months').subtract(5, 'years').toDate(),
            unit: 'months'
          }
        case 'ALL':
          return {
            end: allInfo.end,
            format: allInfo.format,
            name: 'All Time',
            number: allInfo.number,
            start: this.firstResultDate,
            unit: allInfo.unit
          }
        default:
          return {
            end: allInfo.end,
            format: allInfo.format,
            name: 'All Time',
            number: allInfo.number,
            start: this.firstResultDate,
            unit: allInfo.unit
          }
      }
    },
    rangeNameSelection () {
      if (this.chartIndex > 0) {
        return ''
      } else {
        return this.rangeInfo.name
      }
    },
    markersFiltered() {
      if (this.markers.length > 0) {
        if (this.category === 'ALL') {
          return this.markers
        } else {
          return this.markers.filter((marker) => {
            return marker.categorySymbol === this.category
          })
        }
      } else {
        return []
      }
    },
    resultsFiltered() {
      if (this.results.length > 0 && this.markersFiltered.length > 0) {
        return this.results.filter((result) => {
          return this.markersFiltered.map((marker) => {
            return marker.symbol
          }).includes(result.symbol)
        }).map((result) => {
          let marker = this.markersFiltered.filter((marker) => {
            return marker.symbol === result.symbol
          })[0]
          let wellth = 0
          if (this.userProfile.gender == 'female') {
            wellth = parseFloat(Math.min(Math.max((100 * (result.value - marker.wellthMinFemale) / (marker.wellthMaxFemale - marker.wellthMinFemale)),0),100).toFixed(marker.decimals))
          } else {
            wellth = parseFloat(Math.min(Math.max((100 * (result.value - marker.wellthMinMale) / (marker.wellthMaxMale - marker.wellthMinMale)),0),100).toFixed(marker.decimals))
          }
          return {
            // ...marker,
            // ...result,
            categorySymbol: marker.categorySymbol,
            createdOn: result.createdOn.toDate(),
            symbol: result.symbol,
            value: result.value,
            wellth: wellth
          }
        })
      } else {
        return []
      }
    },
    firstResultDate() {
      if (this.resultsFiltered.length > 0) {
        return this.resultsFiltered[this.resultsFiltered.length - 1].createdOn
      } else {
        return moment().startOf('hour').subtract(23, 'hours').toDate()
      }
    },
    markerSummariesUnsorted() {
      if (this.markersFiltered.length > 0) {
        return this.markersFiltered.map((marker) => {
          let markerResults = this.resultsFiltered.filter((result) => {
            return result.symbol === marker.symbol
          })
          if (markerResults.length > 0) {
            let current = markerResults[0]
            let currentResult = current.value
            let lastUpdated = current.createdOn
            let wellth = current.wellth
            let resultDifference = 0
            let wellthDifference = 0
            let sparklineDifference = 0
            if (markerResults.length > 1) {
              let previous = markerResults[1]
              let previousResult = previous.value
              resultDifference = (currentResult - previousResult) / previousResult
              let previousWellth = previous.wellth
              wellthDifference = wellth - previousWellth
            }
            let sparklineResults = markerResults.map((result) => [
              result.createdOn,
              result.value
            ])

            let byPeriod = []

            if (sparklineResults.length > 0) {
              let now = moment().startOf('week').add(1, 'weeks').toDate()
              let start = moment().startOf('week').add(1, 'weeks').subtract(1, 'years').toDate()
              let previousLength = 0
              let previous = 0
              for (let d = new Date(now); d >= start; d = moment(d).subtract(1, 'weeks').toDate()) {
                let previousResults = sparklineResults.filter((point) => {
                  return point[0] <= d
                })
                if (previousResults.length > 0 && previousResults.length != previousLength) {
                  let latestResult = previousResults[0]
                  byPeriod.push([
                    new Date(d),
                    latestResult[1]
                  ])
                  previousLength = previousResults.length
                  previous = latestResult[1]
                } else {
                  byPeriod.push([
                    new Date(d),
                    previous
                  ])
                }
              }
            }

            sparklineDifference = (currentResult - byPeriod[byPeriod.length - 1][1]) * (marker.wellthMaxMale - marker.wellthMinMale)
            
            return {
              ...marker,
              currentResult: currentResult.toFixed(marker.decimals),
              sparklineDifference: sparklineDifference,
              lastUpdated: lastUpdated,
              sparklineResults: byPeriod,
              wellth: wellth.toFixed(1),
              resultDifference: resultDifference,
              wellthDifference: wellthDifference
            }
          } else {
            return {
              ...marker,
              currentResult: null,
              sparklineDifference: null,
              lastUpdated: null,
              sparklineResults: [],
              wellth: null,
              resultDifference: null,
              wellthDifference: null
            }
          }
        })
      } else {
        return []
      }
    },
    markerSummaries() {
      if (this.markerSummariesUnsorted.length > 0) {
        let unsorted = [...this.markerSummariesUnsorted]
        return unsorted.sort((a, b) => {
          if (this.markerSort === 'Name' && this.markerSortAscending) {
            let nameA = a.symbol.toUpperCase() // ignore upper and lowercase
            let nameB = b.symbol.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            } if (nameA > nameB) {
              return 1;
            } else {
              // names must be equal
              return 0
            }
          } else if (this.markerSort === 'Name' && !this.markerSortAscending) {
            let nameA = a.symbol.toUpperCase() // ignore upper and lowercase
            let nameB = b.symbol.toUpperCase() // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            } if (nameA > nameB) {
              return -1;
            } else {
              // names must be equal
              return 0
            }
          } else if (this.markerSort === 'Current' && this.markerSortAscending) {
            return a.resultDifference-b.resultDifference
          } else if (this.markerSort === 'Current' && !this.markerSortAscending) {
            return b.resultDifference-a.resultDifference
          } else if (this.markerSort === 'Wellth' && this.markerSortAscending) {
            return a.wellth-b.wellth
          } else if (this.markerSort === 'Wellth' && !this.markerSortAscending) {
            return b.wellth-a.wellth
          } else if (this.markerSort === 'Age' && this.markerSortAscending) {
            return b.lastUpdated-a.lastUpdated
          } else if (this.markerSort === 'Age' && !this.markerSortAscending) {
            return a.lastUpdated-b.lastUpdated
          } else if (this.markerSort === 'Sparkline' && this.markerSortAscending) {
            return b.sparklineDifference-a.sparklineDifference
          } else if (this.markerSort === 'Sparkline' && !this.markerSortAscending) {
            return a.sparklineDifference-b.sparklineDifference
          }
        })
      } else {
        return []
      }
    },
    // wellthDetailed() {
    //   if (this.resultsFiltered.length > 0) {
    //     return this.resultsFiltered.map((result1, index, array) => {
    //       return {
    //         t: result1.createdOn,
    //         latestResults: this.markersFiltered.map((marker) => {
    //           let previousValues = this.resultsFiltered.filter((result2) => {
    //             return result2.symbol === marker.symbol && result2.createdOn <= result1.createdOn
    //           })
    //           if (previousValues.length > 0) {
    //             let latestResult = previousValues[0]
    //             return {
    //               // ...marker,
    //               categorySymbol: marker.categorySymbol,
    //               symbol: marker.symbol,
    //               // latestResult: latestResult.value,
    //               wellth: latestResult.wellth
    //             }
    //           }
    //         // filter out markers that had no previous results
    //         }).filter((marker) => {
    //           return marker
    //         })
    //       }
    //     })
    //   } else {
    //     return []
    //   }
    // },
    wellthAggregated() {
      // if there are results to aggregate
      if (this.resultsFiltered.length > 0) {

        // initialize list of results to return
        let aggregated = []

        // initialize the current values that will be updated each iteration
        let currentDetailed = {}
        let currentByMarker = {}
        let currentByCategory = {}

        // for each time of new result
        this.resultsFiltered.slice().reverse().forEach((result) => {

          // update currentDetailed
          if (currentDetailed[result.categorySymbol]) {
            currentDetailed[result.categorySymbol][result.symbol] = result.wellth
          } else {
            currentDetailed[result.categorySymbol] = {}
            currentDetailed[result.categorySymbol][result.symbol] = result.wellth
          }

          // update currentByMarker
          currentByMarker[result.symbol] = result.wellth

          // update currentByCategory
          let newSum = 0
          let newLen = 0
          for (let [key, value] of Object.entries(currentDetailed[result.categorySymbol])) {
            newSum += value
            newLen += 1
          }
          currentByCategory[result.categorySymbol] = (newSum / newLen).toFixed(1)

          // calculate currentOverall
          let newSum2 = 0
          let newLen2 = 0
          for (let [key, value] of Object.entries(currentByCategory)) {
            newSum2 += parseFloat(value)
            newLen2 += 1
          }
          let currentOverall =  (newSum2 / newLen2).toFixed(1)

          // determine currentByElement
          // let currentByElement = {}
          // if (this.category === 'ALL') {
          //   currentByElement = {...currentByCategory}
          // } else {
          //   currentByElement = {...currentByMarker}
          // }

          // return datetime, overall wellth, and wellth by element
          // where element is either category or marker depending on what user is looking at
          aggregated.unshift({
            t: result.createdOn,
            wellth: currentOverall,
            wellthByCategory: {...currentByCategory},
            wellthByMarker: {...currentByMarker}
            // wellthByElement: currentByElement
          })
        })

        return aggregated
      } else {
        return []
      }
      

      // if (this.wellthDetailed.length > 0) {
      //   return this.wellthDetailed.map((change) => {
      //     let marker = {}
      //     let category = {}
      //     let overall = []
      //     change.latestResults.forEach((result) => {
      //       overall.push(result.wellth)
      //       if (category[result.categorySymbol]) {
      //         category[result.categorySymbol].push(result.wellth)
      //       } else {
      //         category[result.categorySymbol] = []
      //         category[result.categorySymbol].push(result.wellth)
      //       }
      //       if (marker[result.symbol]) {
      //         marker[result.symbol].push(result.wellth)
      //       } else {
      //         marker[result.symbol] = []
      //         marker[result.symbol].push(result.wellth)
      //       }
      //     })
        
      //     overall = (overall.reduce((total, num) => {
      //       return total + num
      //     }, 0) / overall.length).toFixed(1)
      //     for (let [key, value] of Object.entries(category)) {
      //       category[key] = (value.reduce((acc, next) => acc + next, 0) / value.length).toFixed(1)
      //     }

      //     return {
      //       t: change.t,
      //       wellth: overall,
      //       wellthByCategory: category,
      //       wellthByMarker: marker
      //     }
      //   })
      // } else {
      //   return []
      // }
    },
    // calculate the wellth timeline to be displayed in the top visual:
    wellthTimeline() {
      let now = this.rangeInfo.end //new Date()
      let start = new Date(this.rangeInfo.start)
      let byPeriod = []
      let previousLength = 0
      let previousWellth = 0
      let previousWellthByCategory = {}
      let previousWellthByMarker = {}
      // let previousWellthByElement = {}
      for (let d = new Date(now); d >= start; d = moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()) {
        let previousResults = this.wellthAggregated.filter((point) => {
          return point.t <= d
        })
        if (previousResults.length > 0 && previousResults.length != previousLength) {
          let latestResult = previousResults[0]
          byPeriod.push({
            t: new Date(moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()),
            wellth: latestResult.wellth,
            wellthByCategory: latestResult.wellthByCategory,
            wellthByMarker: latestResult.wellthByMarker
            // wellthByElement: latestResult.wellthByElement
          })
          previousLength = previousResults.length
          previousWellth = latestResult.wellth
          previousWellthByCategory = latestResult.wellthByCategory
          previousWellthByMarker = latestResult.wellthByMarker
          // previousWellthByElement = latestResult.wellthByElement
        } else {
          byPeriod.push({
            t: new Date(moment(d).subtract(this.rangeInfo.number, this.rangeInfo.unit).toDate()),
            wellth: previousWellth,
            wellthByCategory: previousWellthByCategory,
            wellthByMarker: previousWellthByMarker
            // wellthByElement: previousWellthByElement
          })
        }
      }
      return byPeriod
    },
    lineData() {
      return this.wellthTimeline.map((point) => [
        point.t,
        point.wellth
      ])
    },
    wellth100() {
      return this.wellthTimeline.map((point) => [
        point.t,
        100
      ])
    },
    wellth0() {
      return this.wellthTimeline.map((point) => [
        point.t,
        0
      ])
    },
    chartResults() {
      return this.wellthTimeline.map((point) => {
        return point.wellth
      })
    },
    chartResult() {
      return this.chartResults[this.chartIndex]
    },
    radarDataList() {
      // return this.wellthTimeline.map((point) => {
      //   return point.wellthByElement
      // })
      if (this.category === 'ALL') {
        return this.wellthTimeline.map((point) => {
          return point.wellthByCategory
        })
      } else {
        return this.wellthTimeline.map((point) => {
          return point.wellthByMarker
        })
      }
    },
    radarData() {
      return this.radarDataList[this.chartIndex]
    },
    radarIndicatorList() {
      let indicators = []
      if (this.category === 'ALL') {
        indicators = this.markersFiltered.map((marker) => {
          return marker.categorySymbol
        }).filter((v, i, a) => a.indexOf(v) === i)
      } else {
        indicators = this.markersFiltered.map((marker) => {
          return marker.symbol
        }).filter((v, i, a) => a.indexOf(v) === i)
      }
      return indicators.map((indicator) => {
        return { name: indicator, max: 100 }
      })
    },
    radarValueListOfLists() {
      return this.radarDataList.map((radarData) => {
        return this.radarIndicatorList.map((indicator) => {
          return radarData[indicator.name]
        })
      })
    },
    radarValueList() {
      return this.radarValueListOfLists[this.chartIndex]
    },
    difference () {
      return (this.chartResult - this.chartResults[this.chartResults.length - 1]).toFixed(1)
    },
    percentDifference () {
      return Math.min((100 * this.difference / this.chartResults[this.chartResults.length - 1]), 999.9).toFixed(1).toString() + '%'
    },
    line() {
      return {
        legend: {
          type: 'plain',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
          show: true,
          left: 0,
          top: 0,
          padding: [5, 0, 5, 0],
          selected: {
            'Me': true,
            '100': false,
            '0': false,
            'Demographic': false,
            'Global': false
          },
          selectedMode: true,
          selectorLabel: {
            show: false,
            color: '#1FA482'
          },
          textStyle: {
            fontFamily: 'Playfair Display',
          }
        },
        tooltip: {
          show: false
        },
        grid: {
          show: false,
          left: 0,
          top: '15%',
          right: 0,
          bottom: 0
        },
        xAxis: {
          type: 'time',
          show: true,
          position: 'top',
          min (value) {
            return value.min - 0.05 * Math.abs(value.max - value.min)
          },
          max (value) {
            return value.max + 0.05 * Math.abs(value.max - value.min)
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisPointer: {
            show: true,
            type: 'line',
            snap: true,
            z: 99999,
            label: {
              show: true,
              formatter: (params) => {
                return moment(params.value).format(this.rangeInfo.format)
              },
              color: '#787f85',
              backgroundColor: 'transparent',
              fontFamily: 'Playfair Display'
            },
            lineStyle: {
                color: '#787f85'
            },
            triggerTooltip: true,
            value: 0,
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          min (value) {
            if (value.min == value.max) {
              return value.min - 1
            } else {
              return value.min - 0.05 * Math.abs(value.max - value.min)
            }
          },
          max (value) {
            if (value.min == value.max) {
              return value.min + 1
            } else {
              return value.max
            }
          }
        },
        series: [
          {
            type: 'line',
            name: 'Me',
            symbol: 'circle',
            itemStyle: {
              color: this.chartLineColor(this.difference),
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: this.chartLineColor(this.difference)
            },
            symbolSize: 1,
            showSymbol: true,
            data: this.lineData,
            smooth: 0,
            smoothMonotone: 'x'
          },
          {
            type: 'line',
            name: '100',
            symbol: 'circle',
            itemStyle: {
              color: '#1FA482',
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: '#1FA482',
              type: 'dashed'
            },
            showSymbol: false,
            symbolSize: 0,
            data: this.wellth100
          },
          {
            type: 'line',
            name: '0',
            symbol: 'circle',
            itemStyle: {
              color: '#ef5777',
              borderWidth: 0,
              borderColor: 'white'
            },
            lineStyle: {
              color: '#ef5777',
              type: 'dashed'
            },
            showSymbol: false,
            symbolSize: 0,
            data: this.wellth0
          },
          {
            type: 'line',
            name: 'Demographic',
            symbol: 'none',
            itemStyle: {
              color: '#c3d6e2',
              borderWidth: 0,
              borderColor: 'white'
            }
          },
          {
            type: 'line',
            name: 'Global',
            symbol: 'none',
            itemStyle: {
              color: '#e3f2fa',
              borderWidth: 0,
              borderColor: 'white'
            }
          }
        ]
      }
    },
    radar() {
      return {
        tooltip: {
          trigger: 'item',
          show: false,
        },
        legend: {
          type: 'plain',
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 15,
          show: true,
          left: 0,
          top: 0,
          padding: [5, 0, 5, 0],
          selected: {
            'Me': true,
            'Friends': false,
            'Team': false,
            'Global': false
          },
          selectedMode: false,
          selectorLabel: {
            show: false,
            color: this.chartLineColor(this.difference)
          },
          textStyle: {
            fontFamily: 'Playfair Display',
          }
        },
        radar: {
          name: {
            textStyle: {
              color: '#787f85',
              backgroundColor: 'transparent',
              fontFamily: 'Playfair Display',
              borderRadius: 0,
              padding: [0, 0],
              fontSize: 11
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              opacity:0.5
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          splitArea: {
            show: true,
            areaStyle: {
              opacity:0.8
            }
          },
          triggerEvent: true,
          splitNumber: 10,
          indicator: this.radarIndicatorList,
          radius: '70%',
          nameGap: 5,
        },
        series: [{
          name: 'Overall Wellth',
          type: 'radar',
          areaStyle: {
            color: this.chartLineColor(this.difference)
          },
          lineStyle: {
            color: this.chartLineColor(this.difference)
          },
          itemStyle: {
            color: this.chartLineColor(this.difference)
          },
          symbolSize: 6,
          label: {
            show: false
          },
          tooltip: {
            show: false
          },
          data: [
            {
              value: this.radarValueList,
              name: 'Me',
              tooltip: {
                show: true
              }
            },
            {
                name: 'Friends'
            },
            {
                name: 'Team'
            },
            {
                name: 'Global'
            }
          ]
        }],
        grid: {
          show: false,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        }
      }
    }
  },
  methods: {
    changeRange(range) {
      this.range = range
    },
    // openFeedbackModal() {
    //   this.showFeedbackModal = true
    // },
    // closeFeedbackModal() {
    //   this.feedback = {
    //     note: ''
    //   }
    //   this.showFeedbackModal = false
    // },
    // createFeedback() {
    //   this.$store.dispatch('createFeedback', {
    //     api: this.feedback.api,
    //     benchmarking: this.feedback.benchmarking,
    //     email: this.feedback.email,
    //     note: this.feedback.note,
    //     teams: this.feedback.teams
    //   })
    //   this.closeFeedbackModal()
    // },
    changeMarkerSort(markerSort) {
      if (this.markerSort == markerSort) {
        // if the clicked sort column was already selected, reverse direction
        this.markerSortAscending = !this.markerSortAscending
      } else {
        // if the clicked sort column is new, change to it and sort ascending
        this.markerSort = markerSort
        this.markerSortAscending = true
      }
    },
    updateAxisPointer: debounce(
      function (params) {
        let newIndex = params.dataIndex
        if (newIndex == null) {
          newIndex = 0
        }
        this.chartIndex = newIndex
      },
      500, // only fire if at least half second ellapsed between hovers
      {
        'leading': false,
        'maxWait': 500,
        'trailing': true
      } // fire only on the leading part of the batch
    ),
    radarSelection(params) {
      if (params.targetType === 'axisName') {
        if (this.category === 'ALL') {
          this.$router.push({ path: `/portfolio/${params.name}` })
        } else {
          this.$router.push({ path: `/markers/${params.name}` })
        }
      }
    },
    arrow(difference) {
      if (difference > 0) {
        return '&#8593' // up
      } if (difference < 0) {
        return '&#8595' // down
      }
      return '&#8596;&#xfe0e' // sideways
    },
    lineColor(difference) {
      return {
        improving: difference > 0, // green
        deteriorating: difference < 0, // red
        consistent: false // gray
      }
    },
    chartLineColor(difference) {
      if (difference > 0) {
        return '#1FA482'
      } else if (difference < 0) {
        return '#ef5777'
      } else {
        return '#787f85'
      }
    },
    sparkLine(marker) {
      return {
        legend: {
          show: false,
        },
        grid: {
          show: false,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        },
        xAxis: {
          type: 'time',
          show: false,
          position: 'top',
          min (value) {
            return value.min - 0.05 * Math.abs(value.max - value.min)
          },
          max (value) {
            return value.max + 0.05 * Math.abs(value.max - value.min)
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          show: false,
          min (value) {
            if (value.min == value.max) {
              return value.min - 1
            } else {
              return value.min - 0.25 * Math.abs(value.max - value.min)
            }
          },
          max (value) {
            if (value.min == value.max) {
              return value.min + 1
            } else {
              return value.max + 0.25 * Math.abs(value.max - value.min)
            }
          }
        },
        series: [
          {
            type: 'line',
            name: 'Me',
            symbol: 'none',
            itemStyle: {
              color: this.chartLineColor(marker.sparklineDifference)
            },
            lineStyle: {
              color: this.chartLineColor(marker.sparklineDifference)
            },
            data: marker.sparklineResults,
            smooth: 0,
            smoothMonotone: 'x'
          }
        ]
      }
    }
  },
  filters: {
    formatDate(val) {
      if (!val) { return '-' }
      return moment(val).fromNow()
    },
    trimLength(val) {
      if (val.length < 200) { return val }
      return `${val.substring(0, 200)}...`
    },
    formatValue(payload) {
      if (payload.format === 'Minutes') {
        return moment("1900-01-01 00:00:00").add(payload.val, 'Seconds').format("m:ss")
      } else if (payload.format === 'Percent') {
        return payload.val + '%'
      } else if (payload.format === 'None') {
        return payload.val
      } else {
        return payload.val + ' ' + payload.format
      }
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

section {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 3.6rem;
  height: 90vh;
  width: 100%;
  // max-width: 1000px;
  padding: 1rem 5%;
  // margin: auto;

  @media screen and (max-width: 800px) {
    // display: block;
    position: relative;
    height: auto;
  }
}

.col1 {
  flex: 70%;
  height: 100%;
  padding: 0rem;
  overflow: auto;
  min-width: 350px;

  // @media screen and (max-width: 800px) {
    // height: auto;
  // }
}

.wide {
  @media screen and (max-width: 742px) {
    display: none;
  }
}

.narrow {
  @media screen and (min-width: 742px) {
    display: none;
  }
}

.current-wellth {
  height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

img {
  height: 2rem; //100%;
}

.col2 {
  flex: 30%;
  height: 100%;
  padding: 2rem 1rem;
  overflow: hidden;
  min-width: 250px;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.container {
  padding: 1rem;
}

.info-panel {
  padding: 2rem;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba($dark, 0.5);
  overflow: hidden;
}

.info-panel-narrow {
  @media screen and (min-width: 800px) {
    display: none;
  }

  // height: 100%;
  height: 50vh;
}

.big-chart {
  height: 40vh;
  @media screen and (max-width: 800px) {
    height: 30vh;
  }
}

.small-chart {
  height: 100%;
  // height: 10vh;
}

.echarts {
  width: 100%;
  height: 100%;
}

.marker-summary-row {
  border: 1px solid #e6ecf0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  // padding: 0.5rem;
  background: $white;
  height: 100%;

  &:hover {
    background: #f5f8fa;
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6ecf0;
  }
}

.marker-summary {
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 10vh;
  align-items: center;

  * {
    flex: 1 1 0;
    padding: 0 0.5rem 0 0;
  }

  .echarts {
    padding: 0.25rem 0;
  }
}

.marker-headers {
  padding: 0.5rem 0.5rem 0rem 0;
  display: flex;
  flex-direction: row;

  button {
    height: 100%;
    width: 100%;
    background: $white;
    border: 0 solid $primary;
    padding: 0; //0.5rem 2rem;
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;
    flex: 1 1 0;
    text-align: left;

    * {
      text-align: left;
    }

    &:hover {
      background: $white;
      * {
        color: $medium;
      }
    }
  }
}

.rangeName {
  color: $greyedOut;
}

.range-picker {
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;

  button {
    height: 100%;
    width: 100%;
    background: $white;
    border: 0 solid $primary;
    padding: 0.5rem 0rem;
    // border-radius: 7px;
    // box-shadow: 0 0 0 0 rgba($dark, 0.5);
    cursor: pointer;

    &:hover {
      background: $white;
      * {
        color: $medium;
      }
    }
  }
}

.improving {
  color: $improving;
}

.deteriorating {
  color: $deteriorating;
}

.arrow {
  font-family: Helvetica;
  font-size: 1rem;
}

.button-selected {
  color: $medium;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  padding: 1rem;

  * {
    flex: 50%;
  }
}

.markerSort-selected {
  color: $medium;
}

.no-results {
  border: 1px solid #e6ecf0;
  background: $white;
  text-align: center;
  padding: 100px 1rem;
}

.marker-subtitle {
  font-size: 70%;
}

.radar-chart {
  height: 80%;
}

.e-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($dark, 0.4);
  z-index: 999;
  padding: 10rem;

  .e-container {
    position: fixed;
    max-width: 600px;
    top: 2vh;
    bottom: 2vh;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 3px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba($dark, 0.5);
    overflow: auto;

    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
    }

    .post {
      border: 0;
      border-bottom: 1px solid #e6ecf0;

      &:hover {
        background: $white;
      }
    }

    .comment {
      font-size: 14px;
      padding: 1.5rem;
      border-top: 1px solid #e6ecf0;

      &:first-of-type {
        border-top: 0;
      }

      h5 {
        margin: 0 0 0.5rem;
      }

      span {
        display: block;
        font-style: italic;
        font-size: 12px;
        margin-bottom: 0.5rem;
        color: darken($light, 20%);
      }

      p {
        margin: 0;
      }
    }
  }
}

.bottom-padding {
  padding: 0 0 0.25rem 0;
}

textarea {
  height: 20vh;
}

.top-padding {
  padding: 0.5rem 0 0 0;
}

button {
  background: $white;
  border: 0 solid $primary;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba($dark, 0.5);
  cursor: pointer;
  width: 100%;

  &:hover {
    background: $lightest;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background: $primary;
    }
  }
}

hr {
  opacity: 0.3;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  * {
    width: auto;
  }

  label {
    // margin: 0 0 1rem 0.5rem;
    margin-left: 0.5rem;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    color: $secondary;
    text-decoration: underline;
    // padding: 0.5rem;

    &:hover {
      // background-color: #000;
      color: $lightest;
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh;

  * {
    margin: auto;
    padding: 1vh;
    color: $greyedOut;
  }
}

</style>
