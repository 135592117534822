// imports
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init
const firebaseConfig = {
  apiKey: "AIzaSyA1ZPSzgzqroaHYlKM8jK_h-4-gMu1MTlI",
  authDomain: "wellthmark.firebaseapp.com",
  databaseURL: "https://wellthmark.firebaseio.com",
  projectId: "wellthmark",
  storageBucket: "wellthmark.appspot.com",
  messagingSenderId: "993841118277",
  appId: "1:993841118277:web:bd20ecbf54915df743f72c",
  measurementId: "G-HMJHW9YFBD"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
// const analytics = firebase.analytics()

// collection references

// all
const usersCollection = db.collection('users')

// wellthmark
const markersCollection = db.collection('markers')
const resultsCollection = db.collection('results')
const feedbackCollection = db.collection('feedback')

// tally
// const actionsCollection = db.collection('actions')
// const cptCodesCollection = db.collection('cptCodes')
// const visitsCollection = db.collection('visits')

// export utils/refs
export {
  // all
  db,
  auth,
  // analytics,
  usersCollection,

  // wellthmark
  markersCollection,
  resultsCollection,
  feedbackCollection

  // tally
  // actionsCollection,
  // cptCodesCollection,
  // visitsCollection
}
