// imports
import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import VueTyperPlugin from 'vue-typer'
import VueAnalytics from 'vue-analytics'

// use
Vue.use(Vuex)
Vue.use(VueTyperPlugin)
Vue.use(VueAnalytics, {
  id: 'G-HMJHW9YFBD',
  router
})

// firestore cloud snapshots
fb.auth.onAuthStateChanged(user => {
  if (user) {
    // markers
    fb.markersCollection
      .onSnapshot(snapshot => {
        let array = []
        snapshot.forEach(doc => {
          let info = doc.data()
          info.id = doc.id
          array.push(info)
        })
        store.commit('setMarkers', array)
      })
      
    // results
    fb.resultsCollection
      .where('userId', '==', user.uid)
      .orderBy('createdOn', 'desc')
      .onSnapshot(snapshot => {
        let array = []
        snapshot.forEach(doc => {
          let info = doc.data()
          info.id = doc.id
          array.push(info)
        })
        store.commit('setResults', array)
      })

    // visits
    fb.visitsCollection
      .where('userId', '==', user.uid)
      .orderBy('createdOn', 'desc')
      .onSnapshot(snapshot => {
        let array = []
        snapshot.forEach(doc => {
          let info = doc.data()
          info.id = doc.id
          array.push(info)
        })
        store.commit('setVisits', array)
      })

    // actions
    fb.actionsCollection
      .where('userId', '==', user.uid)
      .orderBy('createdOn', 'desc')
      .onSnapshot(snapshot => {
        let array = []
        snapshot.forEach(doc => {
          let info = doc.data()
          info.id = doc.id
          array.push(info)
        })
        store.commit('setActions', array)
      })
    
    // cptCodes
    fb.cptCodesCollection
      .onSnapshot(snapshot => {
        let array = []
        snapshot.forEach(doc => {
          let info = doc.data()
          info.id = doc.id
          array.push(info)
        })
        store.commit('setCptCodes', array)
      })
  }
})

// store
const store = new Vuex.Store({
  state: {
    userProfile: {},
    markers: [],
    cptCodes: [],
    results: [],
    visits: [],
    actions: []
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setMarkers(state, val) {
      state.markers = val
    },
    setCptCodes(state, val) {
      state.cptCodes = val
    },
    setResults(state, val) {
      state.results = val
    },
    setVisits(state, val) {
      state.visits = val
    },
    setActions(state, val) {
      state.actions = val
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          if (errorCode === 'auth/wrong-password') {
            alert('Wrong password.')
          } else {
            alert(errorMessage)
          }
        })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)

      // send login event to google analytics
      // fb.analytics.logEvent('login')
      this.$gtag.event('login', { method: 'Google' })
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          if (errorCode == 'auth/weak-password') {
            alert('The password is too weak.')
          } else {
            alert(errorMessage)
          }
        })

      // create user object in userCollections
      await fb.usersCollection.doc(user.uid).set({
        createdOn: new Date(),
        dob: form.age,
        email: form.email,
        gender: form.gender,
        name: form.name
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)

      // send signup event to google analytics
      // fb.analytics.logEvent('signup')
      this.$gtag.event('signup', { method: 'Google' })
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      let info = userProfile.data()
      info.id = user.uid

      // set user profile in state
      commit('setUserProfile', info)

      // change route to dashboard
      if (router.currentRoute.path === '/login' || router.currentRoute.path === '/signup') {
        router.push('/portfolio/all')
      }
    },
    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', {})

      // redirect to login view
      router.push('/us/en/')
    },
    async createResult({ state, commit }, result) {
      await fb.resultsCollection.add({
        createdOn: new Date(result.date),
        // markerId: result.markerId,
        note: result.note,
        symbol: result.symbol,
        userId: fb.auth.currentUser.uid,
        value: result.value
      }).then(function() {
        console.log("Document successfully created!")
      }).catch(function(error) {
        console.error("Error creating document: ", error)
      })
    },
    async createFeedback({ state, commit }, feedback) {
      await fb.feedbackCollection.add({
        createdOn: new Date(),
        api: feedback.api,
        benchmarking: feedback.benchmarking,
        email: feedback.email,
        note: feedback.note,
        teams: feedback.teams,
        userId: fb.auth.currentUser.uid
      }).then(function() {
        console.log("Document successfully created!")
      }).catch(function(error) {
        console.error("Error creating document: ", error)
      })
    },
    async updateResult({ dispatch }, result) {
      await fb.resultsCollection.doc(result.id).update({
        createdOn: new Date(result.date),
        note: result.note,
        value: parseInt(result.value)
      }).then(function() {
        console.log("Document successfully updated!")
      }).catch(function(error) {
        console.error("Error updating document: ", error)
      })
    },
    async deleteResult({ dispatch }, resultId) {
      await fb.resultsCollection.doc(resultId).delete().then(function() {
        console.log("Document successfully deleted!")
      }).catch(function(error) {
        console.error("Error removing document: ", error)
      })
    },
    async addVisit({ state, commit }, newVisit) {
      let visitId = ''
      await fb.visitsCollection.add({
        createdOn: new Date(),
        note: newVisit.note,
        userId: fb.auth.currentUser.uid
      }).then(function(docRef) {
        console.log("Document successfully created!")
        visitId = docRef.id
      }).catch(function(error) {
        console.error("Error creating document: ", error)
      })

      newVisit.actions.forEach(async (action) => {
        await fb.actionsCollection.add({
          cptCode: action.code,
          createdOn: new Date(),
          userId: fb.auth.currentUser.uid,
          visitId: visitId
        }).then(function() {
          console.log("Document successfully created!")
        }).catch(function(error) {
          console.error("Error creating document: ", error)
        })
      })
    },
    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      const userRef = await fb.usersCollection.doc(userId).update({
        dob: user.age,
        gender: user.gender,
        name: user.name
      })

      dispatch('fetchUserProfile', { uid: userId })
    }
  }
})

export default store
