import Vue from 'vue'
import VueRouter from 'vue-router'
import Portfolio from '../views/Portfolio.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/us/en/',
    name: 'Landing',
    component: () => import('../views/Landing.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/portfolio/:category',
    name: 'Portfolio_Category',
    component: Portfolio,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    meta: {
      requiresAuth: true
    },
    redirect: '/portfolio/all'
  },
  {
    path: '/markers/:markerSymbol',
    name: 'Marker',
    component: () => import('../views/Marker.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/tally',
  //   name: 'Tally',
  //   component: () => import('../views/Tally.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '*',
    name: 'NonExisting',
    component: () => import('../views/Landing.vue'),
    redirect: '/us/en/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  // check for logged in users and unknown markers
  if (requiresAuth && !auth.currentUser) {
    next('/us/en/')

  // check for proper marker symbols
  // } if (to.name === 'Marker' && ! (to.path.toLowerCase() === '/markers/bnch' || to.path.toLowerCase() === '/markers/mile')) {
  //   next('/us/en/')

  // make sure we always have a userProfile
  // if (store.state.userProfile.length === 0) {
  //   await store.dispatch('fetchUserProfile', user)

  } else {
    next()
  }
})

export default router
