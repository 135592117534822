<template>
  <header>
    <div class="logo">
      <router-link to="/portfolio/all">
        <img src="@/assets/logo.svg" alt="logo" />
      </router-link>
    </div>
    <div class="search-bar">
      <input type="text" placeholder="Search coming soon!">
    </div>
    <div class="account">
      <router-link to="/about">
        <button><h5>About</h5></button>
      </router-link>  
    </div>
    <div class="account">
      <router-link to="/account">
        <button><h5>Account</h5></button>
      </router-link>  
    </div>
    <div @click="logout()" class="logout">
      <button><h5>Log Out</h5></button>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

header {
  // @media screen and (min-width: 800px) {
    // position: sticky;
  // }

  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.6rem;
  width: 100%;
  // box-shadow: 0 0 5px 0 rgba($dark, 0.5);
  background: $white;
  margin: 0;
  border: 0;
  padding: 0;
  z-index: 100;

  .logo {
    flex-basis: content;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 2.2vh;

    @media screen and (max-width: 742px) {
      margin-right: auto;
    }

    a {
      height: 100%;
      margin: 0;
      border: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
      }
    }
  }

  .search-bar {
    @media screen and (max-width: 742px) {
      display: none;
    }

    flex-basis: content;
    height: 100%;
    margin: 0;
    margin-right: auto;
    border: 0;
    padding: 2.2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    input {
      background: $white;
      height: 100%;
      width: 40vw;
      border: 0 solid $lightest;
      border-radius: 3px;
      box-shadow: 0 0 5px 0 rgba($dark, 0.5);
      font-size: 16px;
      padding: 10px;
    }
  }

  .account {
    flex-basis: content;
    margin: 0;
    border: 0;
    padding: 1vh;

    @media screen and (max-width: 742px) {
      padding: 0;
    }

    a {
      button {
        background: $white;
        border: 0 solid $primary;
        padding: 0.5rem 1rem;
        border-radius: 7px;
        box-shadow: 0 0 0 0 rgba($dark, 0.5);
        cursor: pointer;

        &:hover {
          background: $lightest;
        }
      
        &:disabled {
          opacity: 0.5;
      
          &:hover {
            background: $primary;
          }
        }
      }
    }
  }

  .logout {
    flex-basis: content;
    margin: 0;
    border: 0;
    padding: 1vh;

    @media screen and (max-width: 742px) {
      padding: 0;
    }

    button {
      background: $white;
      border: 0 solid $primary;
      padding: 0.5rem 1rem;
      border-radius: 7px;
      box-shadow: 0 0 0 0 rgba($dark, 0.5);
      cursor: pointer;
    
      &:hover {
        background: $lightest;
      }
    
      &:disabled {
        opacity: 0.5;
    
        &:hover {
          background: $primary;
        }
      }
    }
  }
}

</style>